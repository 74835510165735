var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "mt-1": _vm.$vuetify.breakpoint.smAndDown,
        "px-1": _vm.$vuetify.breakpoint.smAndDown,
        "mt-3": _vm.$vuetify.breakpoint.mdAndUp,
        "pl-3": _vm.$vuetify.breakpoint.mdAndUp
      }
    },
    [_c("router-view")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }